<template>
  <b-modal
    :id="nombreModal"
    :title="title"
    centered
    size="md"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    ok-title="Retirar"
    :ok-disabled="this.v$.estudiante.$errors.length > 0"
    @ok.prevent="submitOption"
  >
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="12"
          class="mb-1"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
            class="text-center"
          >
            {{item.nombre_completo}}
          </b-form-group>
        </b-col>
        <!-- Field: FECHA RETIRO -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Fecha retiro"
            label-for="fecha_retiro"
          >
            <b-form-datepicker
              v-model="estudiante.fecha_retiro"
              id="fecha_retiro"
              placeholder="Abrir calendario"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.estudiante.fecha_retiro.$error === true
                ? false
                : null"
              @blur.native="v$.estudiante.fecha_retiro.$touch"
            />
              <!-- Mensajes Error Validación -->
              <b-form-invalid-feedback
                v-if="v$.estudiante.fecha_retiro.$error"
                id="fecha_retiroInfo"
                class="text-right"
              >
                <p v-for="error of v$.estudiante.fecha_retiro.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: MOTIVO -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Motivo retiro"
            label-for="motivo_retiro"
          >
            <b-form-input
              id="motivo_retiro"
              placeholder="Ingresa el motivo del retiro"
              v-model="estudiante.motivo_retiro"
              :state="v$.estudiante.motivo_retiro.$error === true
                ? false
                : null"
              @blur.native="v$.estudiante.motivo_retiro.$touch"
            />
            <!-- Mensajes Error Validación -->
            <b-form-invalid-feedback
              v-if="v$.estudiante.motivo_retiro.$error"
              id="motivo_retiroInfo"
              class="text-right"
            >
              <p v-for="error of v$.estudiante.motivo_retiro.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BButton, BOverlay, BFormDatepicker, BFormTimepicker,
  BAlert, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BAlert,
    BModal,
    VBModal,
    vSelect,

  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      cargando: false,
      estudiante: {
        fecha_retiro: null,
        motivo_retiro: 'Sin Motivo',
      },
    }
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  validations() {
    return {
      estudiante: {
        fecha_retiro: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        motivo_retiro: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 150 caracteres.', maxLength(150)),
        },
      }
    }
  },
  mounted() {
  },
  methods: {
    submitOption() {
      this.v$.estudiante.$touch()
      // this.cargando = true
      if (!this.v$.estudiante.$invalid) {
        const data = {
          nombreEstudiante: this.item.nombre_completo,
          nombreModal: this.nombreModal,
          id_matricula: this.item.id_matricula,
          motivo_retiro: this.estudiante.motivo_retiro,
          fecha_retiro: this.estudiante.fecha_retiro,
        }
        this.$emit('processRetirarEstudiante', data)
        // this.cargando = false
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
