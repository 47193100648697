<template>
  <div v-if="typeof data !== 'undefined'">
    <del
      v-if="fecha_retiro !== null"
      :title="title"
    >
      {{
        mayus
          ? data.toUpperCase()
          : data
      }}
    </del>
    <p v-else :title="title">
      {{
        mayus
          ? data.toUpperCase()
          : data
      }}
      <!-- Fecha matrícula: {{formatoDiaMesYear(this.fecha_ingreso)}} -->
    </p>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    BBadge,
  },
  mixins: [formatos],
  data() {
    return {
      title: '',
    }
  },
  props: {
    data: {
      type: String,
      required: false,
    },
    rut: {
      type: Number,
      required: false,
      default: null,
    },
    mayus: {
      type: Boolean,
      required: true,
    },
    fecha_retiro: {
      type: String,
      required: false,
      default: null,
    },
    fecha_ingreso: {
      type: String,
      required: false,
      default: null,
    },
    motivo_retiro: {
      type: String,
      required: false,
      default: null,
    },
    genero: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    rut() {
      this.setTitulo()
    },
  },
  mounted() {
    this.setTitulo()
  },
  methods: {
    setTitulo() {
      const fecha = this.fecha_retiro
        ? `Fecha retiro: ${this.formatoDiaMesYear(this.fecha_retiro)}`
        : 'Fecha retiro: Sin registro'
      const motivo = this.motivo_retiro
        ? `Motivo retiro: ${this.motivo_retiro}`
        : 'Motivo retiro: Sin registro'
      const fecha_ingreso = this.fecha_ingreso
        ? `Fecha ingreso: ${this.formatoDiaMesYear(this.fecha_ingreso)}`
        : 'Fecha ingreso: Sin registro'
      let estado
      if (this.genero === 1) {
        estado = this.fecha_retiro !== null
          ? 'Alumno Retirado'
          : 'Alumno Matriculado'
      } else if (this.genero === 2) {
        estado = this.fecha_retiro !== null
          ? 'Alumna Retirada'
          : 'Alumna Matriculada'
      } else {
        estado = this.fecha_retiro !== null
          ? 'Estudiante Retirado'
          : 'Estudiante Matriculado'
      }
      if (!this.fecha_retiro) {
        this.title = `${estado}
${fecha_ingreso}`
      } else {
        this.title = `${estado}
${fecha_ingreso}
${fecha}
${motivo}`
      }
    }
  },
}
</script>
