<template>
  <div
    v-if="$can('create', modulo)"
    class="d-flex align-items-center "
    :class="posicion === 'end'
      ? 'justify-content-end'
      : 'justify-content-start'
    "
  >
    <b-button
      variant="primary"
      class="btn btn-md"
      :class="`btn btn-${size}`"
      :block="block"
      @click="$emit('processAdd')"
    >
      <span class="text-nowrap">{{ accion }} {{ texto }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  computed: {
    block() {
      return window.screen.width < 766
    },
  },
  props: {
    texto: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
    accion: {
      tyle: String,
      default: 'Crear',
    },
    posicion: {
      tyle: String,
      default: 'end',
    },
    size: {
      tyle: String,
      default: 'md',
    },
  },
  methods: {
  },
}
</script>
