<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Libro Digital Certificado 📚">

          <!-- <b-card-text>
            <br>Por favor, para consultas o sugerencias
          <b-link
            href="https://wa.link/91tkpr"
            target='_blank'
          >
            pincha aquí!
          </b-link>, con gusto te atenderémos.</b-card-text> -->
        </b-card>
      </b-col>

      <b-col
        v-if="false"
        md="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Actualizaciones del sistema 🔄</b-card-title>
            <b-card-text
              v-if="desabilitarActualizaciones > 1"
              class="font-small-2 mr-25 mb-0"
            >
              Este informativo será eliminado en {{ desabilitarActualizaciones }} días.
            </b-card-text>
            <b-card-text
              v-else-if="desabilitarActualizaciones === 1"
              class="font-small-2 mr-25 mb-0"
            >
              Este informativo se eliminará hoy al finalizar el día.
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <b-alert
              show
              variant="primary"
              class="pl-1 pt-1 pb-1 pr-1"
            >
              <b-card-text><b>1</b> - Ya están disponibles los siguientes informes en el sistema </b-card-text>
              </b-card-text>
              <ul>
                <li>
                  <b-card-text class="font-italic">
                    Planilla de notas
                  </b-card-text>
                </li>
                <li>
                  <b-card-text class="font-italic">
                    Informe de Ranking
                  </b-card-text>
                </li>
              </ul>
            </b-alert>
            <!-- <b-alert
              show
              variant="primary"
              class="pl-1 pt-1 pb-1 pr-1"
            >
              <b-card-text><b>2</b> - Se han agregado las siguientes asignaturas en el sistema</b-card-text>
              <app-collapse
                accordion
                type="margin"
                bg-variant="primary"
              >

                <app-collapse-item title="Ver Educación Básica">
                  <b-row>
                    <b-col
                      v-for="curso_basica in cursos_basica"
                      md="3"
                    >
                      <b-table
                        outlined
                        table-variant="light"
                        head-variant="light"
                        small
                        hover
                        noCollapse
                        responsive
                        :fields="fields"
                        :items="curso_basica.asignaturas"
                      >
                        <template #head(nombre)="">
                          {{ curso_basica.curso }}
                        </template>

                      </b-table>
                    </b-col>
                  </b-row>
                </app-collapse-item>

                <app-collapse-item title="Ver Educación Media Adulto">
                  <b-row>
                    <b-col
                      v-for="curso_adulto in cursos_adulto"
                      md="3"
                    >
                      <b-table
                        outlined
                        table-variant="light"
                        head-variant="light"
                        small
                        hover
                        noCollapse
                        responsive
                        :fields="fields"
                        :items="curso_adulto.asignaturas"
                      >
                        <template #head(nombre)="">
                          {{ curso_adulto.curso }}
                        </template>

                      </b-table>
                    </b-col>
                  </b-row>
                </app-collapse-item>

                <app-collapse-item title="Ver Educación Media y Media Técnico">
                  <b-row>
                    <b-col
                      v-for="curso in cursos_media_mediatp"
                      md="3"
                    >
                      <b-table
                        outlined
                        table-variant="light"
                        head-variant="light"
                        small
                        hover
                        noCollapse
                        responsive
                        :fields="fields"
                        :items="curso.asignaturas"
                      >
                        <template #head(nombre)="">
                          {{ curso.curso }}
                        </template>

                      </b-table>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
            </b-alert> -->

          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        v-if="desabilitarActualizaciones > 0"
        md="12"
      >
        <b-card title="Últimas actualizaciones 🔄" no-body>
          <b-card-header>
            <b-card-title>Actualizaciones del sistema 🔄</b-card-title>
            <b-card-text
              v-if="desabilitarActualizaciones > 1"
              class="font-small-2 mr-25 mb-0"
            >
              Este informativo será eliminado en {{ desabilitarActualizaciones }} días.
            </b-card-text>
            <b-card-text
              v-else-if="desabilitarActualizaciones === 1"
              class="font-small-2 mr-25 mb-0"
            >
              Este informativo se eliminará hoy al finalizar el día.
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <b-card-text>
              <br>Últimas actualizaciones
              del libro digital<br>

              <ul class="mt-2 mb-5">
                <li>
                  <p class="h5 text-dark mb-25">
                    Planilla del Curso Notas
                  </p>
                  Esta planilla la encontrarán en Informes > Selecciona el curso > Periodo Anual.
                </li>

                <li class="mt-1">
                  <p class="h5 text-dark mb-25">
                    Planilla del Curso Promedios
                  </p>
                  Esta planilla la encontrarán en Informes > Selecciona el curso > Periodo Anual.
                </li>

              </ul>
                <p class="h5 text-dark mb-25 text-center">
                  ¡Gracias por su compromiso con la educación!
                </p>
              <p class="mb-50 text-center">
                <i>Atte, Softinnova</i><br>

              <!-- <b class="text-primary"><i>Libro Digital</i></b> -->
              </p>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">

        <!-- <mis-libros-vue /> -->
      </b-col>

      <b-col md="12">

        <!-- <matriculas /> -->
      </b-col>


    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BCol, BTable, BAlert,
  BCardHeader, BCardBody, BCardTitle
} from 'bootstrap-vue'

import { $themeConfig } from '@themeConfig'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import MisLibrosVue from '../MisLibros/MisLibros.vue'
import Matriculas from '../Matriculas/Matriculas.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BTable,
    BAlert,
    BCardHeader,
    BCardBody,
    BCardTitle,

    MisLibrosVue,
    Matriculas,

    AppCollapse,
    AppCollapseItem,
    // AppCollapseItemMargin,
  },
  computed: {
    desabilitarActualizaciones() {
      let conteo = true
      let tiempoTranscurrido = Date.now()
      const hoy = new Date(tiempoTranscurrido);
      hoy.toLocaleDateString()
      const fecha_max = new Date('12/24/2023')
      var x = new Date(fecha_max);
      var y = new Date(hoy);

      // segundos = milisegundos/1000
      // minutos = segundos/60
      // horas = minutos/60
      // Días = horas/24

      const diffInDays = Math.floor((x - y) / (1000 * 60 * 60 * 24));

      return diffInDays + 1
    },
  },
  data() {
    return {
      mainProps: { width: 56, height: 37 },
      // fields: [
      //   {
      //     key: 'nombre',
      //     label: 'Asignaturas',
      //     sortable: true,
      //     thStyle: {
      //       width: '100px !important',
      //       display: 'table-cell',
      //       'vertical-align': 'middle',
      //     },
      //   },
      // ],
      // cursos_basica: [
      //   {
      //     curso: '1ero Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Inglés'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '2do Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Inglés'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '3ro Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Inglés'
      //       },
      //       {
      //         nombre: 'Taller de Matemática'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '4to Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Inglés'
      //       },
      //       {
      //         nombre: 'Taller de Matemática'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '5to Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '6to Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '7mo Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '8vo Básico A',
      //     asignaturas: [
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //     ]
      //   },
      // ],
      // cursos_adulto: [
      //   {
      //     curso: '1er nivel (1° y 2° Medio) A',
      //     asignaturas: [
      //       {
      //         nombre: 'Educación Matemática'
      //       },
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Convivencia Social'
      //       },
      //       {
      //         nombre: 'Consumo y Calidad de Vida'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '2do nivel (3° y 4° Medio) A',
      //     asignaturas: [
      //       {
      //         nombre: 'Educación Matemática'
      //       },
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Inserción Laboral'
      //       },
      //       {
      //         nombre: 'Tecnologías de la Información y de las telecomunicaciones'
      //       },
      //     ]
      //   },
      // ],
      // cursos_media_mediatp: [
      //   {
      //     curso: '1ero Medio A',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '1ero Medio B',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '1ero Medio C',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '1ero Medio D',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },

      //   {
      //     curso: '2do Medio A',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '2do Medio B',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '2do Medio C',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '2do Medio D',
      //     asignaturas: [
      //       {
      //         nombre: 'Biología'
      //       },
      //       {
      //         nombre: 'Física'
      //       },
      //       {
      //         nombre: 'Química'
      //       },
      //       {
      //         nombre: 'Artes Visuales'
      //       },
      //       {
      //         nombre: 'Música'
      //       },
      //       {
      //         nombre: 'Taller Exploratorio'
      //       },
      //     ]
      //   },

      //   {
      //     curso: '3ero Medio A',
      //     asignaturas: [
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Límites, derivadas e Integrales'
      //       },
      //       {
      //         nombre: 'Taller de Literatura'
      //       },
      //       {
      //         nombre: 'Seminario de Filosofía'
      //       },
      //       {
      //         nombre: 'Ciencias de la Salud'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Matemática'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '3ero Medio B',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //       {
      //         nombre: 'Aprendizaje en la empresa'
      //       },
      //       {
      //         nombre: 'Análisis de la Experiencia en la Empresa'
      //       },
      //       {
      //         nombre: 'Instalación de Motores Eléctricos y Equipos de Calefacción'
      //       },
      //       {
      //         nombre: 'Instalación Eléctricas Domiciliarias'
      //       },
      //       {
      //         nombre: 'Elaboración de Proyectos Eléctricos'
      //       },
      //       {
      //         nombre: 'Mantenimiento de Máquinas, Equipos y Sistemas Eléctricos'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '3ero Medio C',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //       {
      //         nombre: 'Aprendizaje en la empresa'
      //       },
      //       {
      //         nombre: 'Análisis de la Experiencia en la Empresa'
      //       },
      //       {
      //         nombre: 'Utilización de Información Contable'
      //       },
      //       {
      //         nombre: 'Gestión Comercial y Tributaria'
      //       },
      //       {
      //         nombre: 'Atención de Clientes'
      //       },
      //       {
      //         nombre: 'Organización de Oficinas'
      //       },
      //       {
      //         nombre: 'Aplicaciones Informáticas para la Gestión Administrativa'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '3ero Medio D',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //       // {
      //       //   nombre: 'Aprendizaje en la empresa'
      //       // },
      //       // {
      //       //   nombre: 'Análisis de la Experiencia en la Empresa'
      //       // },
      //       {
      //         nombre: 'Instalación de Redes de Área Local, Cableadas e Inalámbricas'
      //       },
      //       {
      //         nombre: 'Ensamblado y Configuración de Computadores y Equipos Terminales Portátiles'
      //       },
      //       {
      //         nombre: 'Instalación y Explotación de Software de Aplicaciones Productivas'
      //       },
      //       {
      //         nombre: 'Configuración y Puesta en Servicio de Aplicaciones en Redes de Área Local'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '4to Medio A',
      //     asignaturas: [
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Biología Celular y Molecular'
      //       },
      //       {
      //         nombre: 'Participación y Argumentación en Democracia'
      //       },
      //       {
      //         nombre: 'Geografía, Territorio y Desafíos Socioambientales'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //       {
      //         nombre: 'Taller de Computación'
      //       },
      //       {
      //         nombre: 'Taller de Lenguaje'
      //       },
      //       {
      //         nombre: 'Taller de Matemática'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '4to Medio B',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Aprendizaje en la empresa'
      //       },
      //       {
      //         nombre: 'Análisis de la Experiencia en la Empresa'
      //       },
      //       {
      //         nombre: 'Instalación de Sistemas de Control Eléctrico Industrial'
      //       },
      //       {
      //         nombre: 'Instalaciones Eléctricas Industriales'
      //       },
      //       {
      //         nombre: 'Instalación de Equipos Electrónicos de Potencia'
      //       },
      //       {
      //         nombre: 'Automatización de Sistemas Eléctricos Industriales'
      //       },
      //       {
      //         nombre: 'Emprendimiento y Empleabilidad'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '4to Medio C',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       {
      //         nombre: 'Aprendizaje en la empresa'
      //       },
      //       {
      //         nombre: 'Análisis de la Experiencia en la Empresa'
      //       },
      //       {
      //         nombre: 'Legislación Laboral'
      //       },
      //       {
      //         nombre: 'Cálculo de Remuneración, Finiquitos y Obligaciones Laborales'
      //       },
      //       {
      //         nombre: 'Desarrollo y Bienestar del Personal'
      //       },
      //       {
      //         nombre: 'Dotación de Personal'
      //       },
      //       {
      //         nombre: 'Emprendimiento y Empleabilidad'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //     ]
      //   },
      //   {
      //     curso: '4to Medio F',
      //     asignaturas: [
      //       {
      //         nombre: 'Filosofía'
      //       },
      //       {
      //         nombre: 'Educación Ciudadana'
      //       },
      //       {
      //         nombre: 'Ciencias para la Ciudadanía'
      //       },
      //       {
      //         nombre: 'Educación Física y Salud'
      //       },
      //       // {
      //       //   nombre: 'Aprendizaje en la empresa'
      //       // },
      //       // {
      //       //   nombre: 'Análisis de la Experiencia en la Empresa'
      //       // },
      //       {
      //         nombre: 'Configuración de la Seguridad en Redes de Área Local'
      //       },
      //       {
      //         nombre: 'Mantenimiento y Actualización de Hardware en Redes de Área Local'
      //       },
      //       {
      //         nombre: 'Mantenimiento y Actualización de Software en Redes de Área Local'
      //       },
      //       {
      //         nombre: 'Recuperación y Respaldo de Información en Redes de Área Local'
      //       },
      //       {
      //         nombre: 'Emprendimiento y Empleabilidad'
      //       },
      //       {
      //         nombre: 'Orientación'
      //       },
      //     ]
      //   },
      // ]
    }
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
