<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
    v-if="$can('read', 'matriculas')"
  >
    <b-card>
      <!-- filtros: CURSO, PERIODO, INFORME -->
      <b-row
        class="mb-1"
      >
        <!-- CURSOS -->
        <b-col
          cols="12"
          md="6"
        >
          <b-overlay
            :show="cargandoCursos"
            spinner-variant="primary"
            :variant="$store.state.appConfig.layout.skin === 'dark'
            ? 'black-light'
            : ''"
          >
            <b-form-select
              v-model="selectedCurso"
              :options="optionsCursos"
              @change="changeCurso"
            />
          </b-overlay>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mt-md-0"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
            size="md"
            :cols="false"
          />

        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="totalEstudiantes"
          />
        </b-col>
        <b-col
          md="2"
          sm="2"
          class="text-center my-25 my-md-2"
        >
          Matriculados: {{ matriculados }}
        </b-col>
        <b-col
          md="2"
          sm="2"
          class="text-center my-25 my-md-2"
        >
          Retirados: {{ cuentaRetirados }}
        </b-col>
        <b-col
          md="2"
          sm="2"
          class="text-center my-25 my-md-2"
        >
          Total: {{ totalEstudiantes }}
        </b-col>
        <b-col
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="Matricula"
            modulo="matriculas"
            @processAdd="goToAddMatricula"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            small
            striped
            hover
            noCollapse
            class="mt-1 rounded"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Header: Check -->
            <template #head(colCheck)="data">

              <b-form-checkbox
                :id="data.label"
                v-model="chkTodo"
              />

            </template>

            <!-- col: ROL -->
            <template #cell(idrol)="data">
              {{ data.item.id_persona_rol_alumno }}
            </template>

            <!-- col: RUT -->
            <template #cell(rut_completo)="data">
              <estudiante-retirado
                :data.sync="data.item.rut_completo"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :motivo_retiro.sync="data.item.motivo_retiro"
                :mayus="false"
              />
            </template>

            <!-- col: NOMBRE -->
            <template #cell(nombre_completo)="data">
              <estudiante-retirado
                :data.sync="data.item.nombre_completo"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :motivo_retiro.sync="data.item.motivo_retiro"
                :mayus="true"
              />
            </template>

            <!-- col: CURSO -->
            <template #cell(nombre_curso)="data">
              <estudiante-retirado
                :data.sync="data.item.nombre_curso"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :motivo_retiro.sync="data.item.motivo_retiro"
                :mayus="false"
              />
            </template>

            <!-- col: FECHA MATRICULA -->
            <template #cell(fecha_matricula)="data">
              <estudiante-retirado
                :data.sync="data.item.fecha_matricula"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :motivo_retiro.sync="data.item.motivo_retiro"
                :mayus="false"
              />
            </template>

            <!-- col: FECHA RETIRO -->
            <template #cell(fecha_retiro)="data">
              {{data.item.fecha_retiro}}
            </template>

            <!-- col: ACCIONES -->
            <template #cell(acciones)="data">
              <matriculas-retirar
                :nombreModal="`retirar_${data.item.id}`"
                :item="data.item"
                title="Retirar estudiante"
                @processRetirarEstudiante="retirarEstudiante"
              />
              <!-- {{ data.item }} -->
              <matriculas-acciones
                modulo="matriculas"
                :data="data.item"
                :nombreModalRetirar="`retirar_${data.item.id}`"
                @processGoToUpdate="goToUpdate(data.item)"
              />
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0 mt-1"
          />
        </b-col>

      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BFormSelect,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECICLADOS
import btnCrear from '../components/List/btnCrear.vue'
import inputFiltro from '../components/List/inputFiltro.vue'
import btnMostrar from '../components/List/btnMostrar.vue'
import spinner from '../components/spinner.vue'
import colNombreImg from '../components/List/colNombreImg.vue'
import estudianteRetirado from '../components/List/estudianteRetirado.vue'

// COMPONENTES HIJOS
import MatriculasAcciones from './components/MatriculasAcciones.vue'
import MatriculasRetirar from './components/MatriculasRetirar.vue'
import { title } from '@core/utils/filter';
import { length } from '@validations';

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCard,
    BFormSelect,

    // COMPONENTES RECICLADOS
    btnCrear,
    inputFiltro,
    btnMostrar,
    spinner,
    colNombreImg,
    estudianteRetirado,

    // COMPONENTES HIJOS
    MatriculasAcciones,
    MatriculasRetirar,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // reloads
      cargando: false,
      spinner: false,
      cargandoCursos: true,

      // tabla
      perPage: 100,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [50, 100, 200],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      // info
      selectedCurso: null,
      cuentaRetirados: 0,
      optionsCursos: [],
      items: [],
      fields: [
        // {
        //   key: 'colCheck',
        //   label: 'chkHeader',
        //   sortable: false,
        //   thStyle: {
        //     width: '0px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        // {
        //   key: 'idrol',
        //   label: 'id_rol',
        //   sortable: true,
        //   thStyle: {
        //     width: '1% !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'rut_completo',
          label: 'RUT',
          sortable: true,
          thStyle: {
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '25% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_curso',
          label: 'Curso',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_matricula',
          label: 'Fecha Matrícula',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_retiro',
          label: 'Fecha Retiro',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'estado',
        //   label: 'Estado',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     'text-align': 'center',
        //     width: '80px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '5% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getMatriculas: 'matriculas/getMatriculas',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
    }),
    matriculados() {
      let totalMatriculados = this.items.length - this.cuentaRetirados
      if (typeof this.items.length === 'undefined') {
        totalMatriculados = 0
      }
      return totalMatriculados
    },
    totalEstudiantes() {
      console.log('this.items.length :', this.items.length)
      return typeof this.items.length === 'undefined' ? 0 : this.items.length
    },
  },
  watch: {
    getMatriculas(val) {
      this.totalRows = val.length
      this.items = []
      this.setMatriculas(this.getMatriculas)
    },
  },
  mounted() {
    this.setTableList()
    this.cargarMatriculas()
    this.cargarCursos()
  },
  methods: {
    ...mapActions({
      fetchMatriculasEstablecimiento: 'matriculas/fetchMatriculasEstablecimiento',
      updateMatriculaPeriodo: 'matriculas/updateMatriculaPeriodo',
      removeMatriculas: 'matriculas/removeMatriculas',
      retirarMatricula: 'matriculas/retirarMatricula',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
    }),
    ...mapMutations('matriculas', ['setMatricula']),
    cargarMatriculas() {
      this.cargando = true
      this.fetchMatriculasEstablecimiento(this.getUser.id_establecimiento).then(() => {
        this.cargando = false
      })
    },
    cargarCursos() {
      this.optionsCursos = [
        {
          value: null,
          text: 'Filtrar estudiantes por curso...'
        }
      ]

      this.fetchCursosPersonaRol().then(() => {
        this.getCursosPersonaRol.forEach(curso => {
          const nombreCurso = curso.nombre+' '+curso.letra
          this.optionsCursos.push(
            {
              value: curso.id,
              text: nombreCurso,
            }
          )
        })
      })
      this.cargandoCursos = false
    },
    setTableList() {
      if (this.$can('update', 'matriculas')
        || this.$can('retirar', 'matriculas')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    setMatriculas(matriculas) {
      this.items = []
      this.cuentaRetirados = 0
      for (let i = 0; i < matriculas.length; i++) {
        let matricula = matriculas[i];
        matricula['rut_completo'] = this.formatRut(matricula.rut + '' + matricula.dv)
        matricula['nombre_completo'] = `${matricula.primer_apellido}
            ${matricula.segundo_apellido}
            ${matricula.nombre}
            ${matricula.segundo_nombre !== null
              ? matricula.segundo_nombre
              : ''}
          `
        matricula['nombre_curso'] = matricula.nombre_grado + ' ' + matricula.letra
        matricula['fecha_matricula'] = matricula.fecha_ingreso !== null
          ? this.formatoDiaMesYear(matricula.fecha_ingreso)
          : matricula.fecha_ingreso
        matricula['fecha_retiro'] = matricula.fecha_retiro !== null
          ? this.formatoDiaMesYear(matricula.fecha_retiro)
          : matricula.fecha_retiro
        this.cuentaRetirados = matricula.fecha_retiro !== null
          ? this.cuentaRetirados + 1
          : this.cuentaRetirados
      }
      this.items = matriculas
    },

    changeCurso() {
      if (this.selectedCurso === null) {
        this.setMatriculas(this.getMatriculas)
      } else {
        const matriculas = this.getMatriculas.filter(m => m.id_cursos === this.selectedCurso)
        this.setMatriculas(matriculas)
      }
    },

    goToAddMatricula() {
      this.$router.replace({
        name: 'matriculas-create',
      })
    },

    goToUpdate(matricula) {
      this.setMatricula(matricula)
      this.$router.push({
        name: 'matriculas-update',
      })
    },

    retirarEstudiante(retiro) {
      this.$swal({
        title: 'Retirar estudiante!',
        text: `Estás seguro que deseas retirar al estudiante
          "${retiro.nombreEstudiante}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, retirar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.retirarMatricula(retiro).then(() => {
            const statusMatricula = store.state.matriculas.status
            if (statusMatricula === 'success') {
              const title = 'Estudiante retirado con éxito 👍'
              const text = `"El estudiante ${retiro.nombreEstudiante}" ha sido eliminado!`
              this.$toast({
                component: ToastificationContent,
                props: {
                  title,
                  icon: 'CheckIcon',
                  text,
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 6000,
              })
              this.$bvModal.hide(retiro.nombreModal)
              const item = this.items.find(i => i.id_matricula === retiro.id_matricula)
              item.fecha_retiro = retiro.fecha_retiro
              item.motivo = retiro.motivo
            } else {
              this.$swal({
                title: 'Error!',
                text: store.state.matriculas.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
